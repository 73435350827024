.add-collaborators-container {
	background-color: #fff;
	@include dark-mode {
		background-color: #0f0f0f;
	}
	.page-title-text {
		color: #000;
		@include dark-mode {
			color: #fff;
		}
	}
	.search-container {
		display: flex;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.06);
		@include dark-mode {
			background-color: #393939;
		}
		border-radius: 40px;
		height: 50px;
		padding: 16px 11px;
		.search-icon {
			fill: #000;
		}
		.search-input {
			font-weight: 500;
			border: none;
			outline: none;
			background-color: transparent;
			color: #000;
			@include dark-mode {
				color: #fff;
			}
		}
		.search-input::placeholder {
			color: #000;
			@include dark-mode {
				color: #fff;
			}
		}
	}
	.collaborators-list {
		height: calc(100vh - 150px);
		overflow-y: scroll;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		padding-bottom: 10px;

		@media (min-width: 768px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: 992px) {
			grid-template-columns: repeat(3, 1fr);
		}
		.collaborator-card {
			background-color: var(--bs-grey-card-bg);
			border: 1px solid var(--bs-grey-card-border-color);
			@include dark-mode {
				background-color: #000000;
				border: 1px solid #1e1e1e;
			}
			border-radius: 8px;
			height: 180px;
			width: 100%;
			padding: 16px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			cursor: pointer;

			.user-avatar {
				width: 50px;
				height: 50px;
				.first-letter {
					width: 50px;
					height: 50px;
				}
				img {
					width: 50px !important;
					height: 50px !important;
				}
			}
		}
		.collaborator-card:hover {
			border: 1px solid #1e1e1e;
			@include dark-mode {
				border: 1px solid grey;
			}
		}
	}
	.collaborator-dot {
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: $primary;
		position: absolute;
		right: 0;
		top: 0;
		display: block;
	}
	.hash-btn {
		background-color: var(--bs-grey-bg);
		color: #000;
		border-color: var(--bs-grey-bg);
		@include dark-mode {
			background-color: rgb(100, 100, 100);
			color: #000;
			border-color: rgb(100, 100, 100);
		}
	}
	.hash-btn:hover {
		border: 1px solid #000;
		@include dark-mode {
			color: #fff;
			border: 1px solid rgb(100, 100, 100);
		}
	}

	.filter-lists {
		transition: all 0.2s linear;
		transform-origin: bottom right;
	}
}

.add-collaborator-page {
	background-color: #fff;

	@include dark-mode {
		background-color: #0f0f0f;
	}

	.collaborators-list {
		height: calc(100vh - 248px) !important;
		overflow-y: scroll;
	}
}
