//
//  App
//

.app {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;

	// Mobile Status
	@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
		overflow: hidden;
	}
}

.dropzone {
	display: block;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	width: 100%;
	max-width: 100%;
	height: 200px;
	padding: 5px 10px;
	font-size: 14px;
	line-height: 22px;
	color: #334151;
	background-color: #fff;
	background-image: none;
	text-align: center;
	border: 1px solid #d2d2d2;
	-webkit-transition: border-color 0.15s linear;
	transition: border-color 0.15s linear;
	border-radius: 5px;
	z-index: 0;
}

.wizard-progress-btn {
	.me-0 {
		margin-right: 0 !important;
	}
}
//Draggable wizard modal style
.wizard-progress-pill-btn {
	width: 30px;
	height: 5px;
	.me-0 {
		margin-right: 0 !important;
	}
}
.wizard-sheet-header {
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	transform: translateY(-5px);
}
.wizard-sheet-footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 100;
}
#root {
	// min-height: 100vh;
}

.wizardModal {
	form {
		box-shadow: none !important;
	}

	.wizard {
		padding-top: 0 !important;
	}

	.card-header {
		padding-top: 0 !important;
	}

	.card-body {
		padding: 0;
		box-shadow: none !important;
	}
}

// .css-vaz3ah-control {
// 	.css-1dimb5e-singleValue {
// 		display: none !important
// 	}
// }
.grid-image {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	gap: 5px;
	.image-item {
		overflow: hidden;
		cursor: pointer;
		img {
			object-fit: cover;
		}
	}
}
.drop .dropzone:hover {
	background-size: 30px 30px;
	background-image: -webkit-linear-gradient(
		135deg,
		#e7ecf6 25%,
		transparent 25%,
		transparent 50%,
		#e7ecf6 50%,
		#e7ecf6 75%,
		transparent 75%,
		transparent
	);
	background-image: linear-gradient(
		-45deg,
		#e7ecf6 25%,
		transparent 25%,
		transparent 50%,
		#e7ecf6 50%,
		#e7ecf6 75%,
		transparent 75%,
		transparent
	);
	-webkit-animation: stripes 2s linear infinite;
	animation: stripes 2s linear infinite;
}

.text-error {
	color: rgb(255, 22, 22) !important;
}

.wizard {
	height: 100% !important;
}

.select-product,
.select-template,
.select-action {
	.dropdown-toggle::after {
		display: none !important;
	}
}

.table-image-check .form-check {
	position: absolute !important;
	top: 5px !important;
	left: 5px !important;
}

#portal-root {
	.modal.fade.show:has(.alert-popup) {
		z-index: 100000000 !important;
	}

	.modal.fade.show:has(.alert-popup) + .modal-backdrop.fade.show {
		z-index: 99999999 !important;
	}
}

@media (min-width: 768px) {
	.w-md-50 {
		width: 50% !important;
	}
}

.capitalize {
	text-transform: capitalize;
}

.bottom-0 {
	bottom: 0;
}

.aflo-component {
	background-color: var(--bs-card-bg);

	.aflo-container {
		background-color: var(--bs-card-bg);
		border: 1px solid var(--bs-card-border-color);
	}
}

.aflo-title,
.aflo-text,
.aflo-price,
.aflo-subtitle2 {
	color: var(--bs-black-text) !important;
}

.aflo-headerTableCell {
	background-color: var(--bs-card-bg) !important;
}

.aflo-noUsageData,
.aflo-styledSelect {
	background-color: var(--bs-card-bg) !important;
	color: var(--bs-black-text) !important;
}
.aflo-styledSelect svg,
.aflo-stripeLink svg {
	fill: var(--bs-black-text) !important;
}
.aflo-customMenuItem {
	color: #000 !important;
}
.aflo-barGraph {
	background-color: var(--bs-white);
}
.aflo-button.primary {
	background-color: var(--bs-alfo-primary-bg);
	border: 1px solid var(--bs-primary);
	.aflo-text {
		color: var(--bs-white) !important;
	}
}
.aflo-button.primary:hover {
	background-color: var(--bs-primary);
	.aflo-text {
		color: var(--bs-white) !important;
	}
}
.aflo-filterButtonsButton.selected {
	border: 1px solid var(--bs-primary) !important;
	color: var(--bs-primary) !important;
	// background-color: ;
}
.aflo-button.white {
	background-color: var(--bs-card-bg);
	border: 1px solid var(--bs-card-border-color);
	.aflo-text {
		color: var(--bs-black-text) !important;
	}
}
.aflo-button.white:hover {
	background-color: var(--bs-white);
	.aflo-text {
		color: #000 !important;
	}
}

/* styles.css */

.aflo-pdfContainer {
	color: #000 !important;
}

.shrunk-text {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		font-size: smaller !important;
	}
	p {
		margin-bottom: 0 !important;
	}
}

.css-lqxrdz {
	text-align: center;
	margin: 32px auto;
}
.css-1i0qn8s,
.css-14wofj3,
.css-t7mml1 {
	background-color: #0078d4;
	width: 20px;
	height: 20px;
	margin: 20px;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: animation-j72l4z 0.75s 0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
	animation: animation-j72l4z 0.75s 0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@keyframes animation-j72l4z {
	0% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
	45% {
		-webkit-transform: scale(0.1);
		-moz-transform: scale(0.1);
		-ms-transform: scale(0.1);
		transform: scale(0.1);
		opacity: 0.7;
	}

	80% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
