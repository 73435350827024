//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.bg-grey {
	background: #f2f2f2 !important;
}

.swal2-container {
	z-index: 2000000000 !important;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
	height: 50px !important;
	width: 20px !important;
}
.css-1ntpn9b {
	@include dark-mode {
		color: rgb(255, 255, 255) !important;
	}
}

.rotating-upload-icon {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.rotating-circle {
	position: absolute;
	border: 3px solid #007bff; /* Circle color */
	border-top: 3px solid transparent;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	animation: rotate 2s linear infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.custom-video-player {
	position: relative;
	max-width: 100%;
	overflow: hidden;
}
.react-easy-crop {
	width: 100%;
}
.custom-button {
	position: absolute;
	top: 5%;
	right: 0%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border: none;
	cursor: pointer;
	z-index: 2; /* Ensure the button appears above the video */
}

.tracking-in-expand {
	-webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
	animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
.fade-in-top {
	-webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.shadow-drop-2-bottom:hover {
	-webkit-animation: shadow-drop-2-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: shadow-drop-2-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.jello-horizontal:active {
	-webkit-animation: jello-horizontal 0.9s both;
	animation: jello-horizontal 0.9s both;
}

@-webkit-keyframes tracking-in-expand {
	0% {
		letter-spacing: -0.5em;
		opacity: 0;
	}
	40% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}
@keyframes tracking-in-expand {
	0% {
		letter-spacing: -0.5em;
		opacity: 0;
	}
	40% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@-webkit-keyframes shadow-drop-2-bottom {
	0% {
		-webkit-transform: translateZ(0) translateY(0);
		transform: translateZ(0) translateY(0);
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
	100% {
		-webkit-transform: translateZ(50px) translateY(-12px);
		transform: translateZ(50px) translateY(-12px);
		-webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
		box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
	}
}
@keyframes shadow-drop-2-bottom {
	0% {
		-webkit-transform: translateZ(0) translateY(0);
		transform: translateZ(0) translateY(0);
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
	100% {
		-webkit-transform: translateZ(50px) translateY(-12px);
		transform: translateZ(50px) translateY(-12px);
		-webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
		box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
	}
}

@-webkit-keyframes jello-horizontal {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	30% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		-webkit-transform: scale3d(1.15, 0.85, 1);
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes jello-horizontal {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	30% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		-webkit-transform: scale3d(1.15, 0.85, 1);
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
