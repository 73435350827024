.nav-wrapper {
	width: 100%;
	@media (min-width: 920px) {
		width: 280px;
	}
}
.side-nav {
	background-color: var(--bs-body-bg);
	@media (max-width: 900px) {
		transition: all 0.4s;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 100;
	}
	.nav-item-wrapper {
		overflow: hidden;
		padding: 15px 10px;
		border-radius: 5px;
	}
	.nav-item {
		color: var(--bs-white);
		text-transform: capitalize;
		text-decoration: none;
		font-size: initial;
	}
	.active-nav-item {
		background: linear-gradient(to right, #5b6276, #363a44);
	}
	.end-line {
		background-color: var(--bs-primary);
		height: 65%;
		width: 8px;
		border-radius: 10px;
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translate(-0%, -50%);
	}
}
.side-nav.show {
	left: 0;
}

.side-nav.hide {
	left: -100%;
}

.content-wrapper {
	background-color: #2e3139;
}
.content-wrapper-card {
	@include dark-mode {
		background-color: #2e3139;
	}
}

.search-container {
	background-color: rgba(0, 0, 0, 0.06);
	height: 50px;

	@include dark-mode {
		background-color: #0f0f0f99;
	}
	border-radius: 40px;
	padding: 15px 12px;
	position: relative;
	.search-icon {
		fill: #000;
		@include dark-mode {
			fill: $grey-40;
		}
		position: absolute;
		left: 5px;
		top: 50%;
		transform: translateY(-50%) scale(0.8);
	}
	.search-input {
		font-weight: 500;
		border: none;
		outline: none;
		background-color: transparent;
		color: #000;
		@include dark-mode {
			color: $grey-40;
		}
		width: 100%;
		padding-left: 25px;
	}
}
.search-container.home-search {
	border-radius: 0;
	@include dark-mode {
		background-color: #0b0c0e;
	}
	input {
		font-weight: normal;
		border: none;
		outline: none;
		background-color: transparent;
		color: #000;
		font-size: 16px !important;
		@include dark-mode {
			color: $grey-40;
		}
		width: 100%;
		padding-left: 15px;
		&::placeholder {
			color: #7b7b7d !important;
			font-weight: normal;
			font-weight: normal;
		}
	}
}

.overview-card {
	background-color: rgba(0, 0, 0, 0.06);
	min-height: 280px;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 12px;
	.small-text {
		font-size: 12px;
	}
	@include dark-mode {
		background-color: #0f0f0f99;
	}
	padding: 12px;
	.overview-card-header,
	.overview-card-body {
		padding: 10px;
	}
}
.metrics-card {
	background-color: rgba(0, 0, 0, 0.06);
	min-height: 180px;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 12px;
	@include dark-mode {
		background-color: #0f0f0f99;
	}
	padding: 12px;
	.metrics-card-header,
	.metrics-card-body {
		padding: 10px;
	}
}

.campaign-card {
	min-width: 300px;
	border-radius: 10px;
	border: 1px solid #f1f2f4;
	@include dark-mode {
		border: 1px solid #f1f2f41f;
	}
	padding: 15px;
	p {
		margin-bottom: 0;
	}
	.campaign-card-header {
		@media (min-width: 720px) {
			display: flex;
			align-items: start;
			justify-content: space-between;
		}
		margin-bottom: 10px;
		.small-text {
			font-size: 12px;
		}
	}
	.name-badge {
		flex-shrink: 0;
		background-color: var(--bs-primary);
		@include dark-mode {
			background-color: rgba(0, 55, 255, 0.484);
		}
		padding: 10px;
		border-radius: 10px;
		height: 80px;
		width: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 3px solid var(--bs-primary);
		p {
			font-size: 18px;
		}
	}
	.status-badge {
		padding: 3px 10px;
		border-radius: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		color: #05b05e;
		border: 3px solid #05b05e;
		background-color: #f6fef9;
		font-size: 12px;
		@include dark-mode {
			background-color: #05b05d65;
			color: #05b05e;
		}
		.dot {
			height: 5px;
			width: 5px;
			border-radius: 50%;
			background-color: #05b05e;
		}
	}
	.more-btn {
		border: 1px solid #f1f2f4;
		background-color: #f6fef9;
		border-radius: 8px;
		color: #000;
		cursor: pointer;
		@media (max-width: 700px) {
			position: absolute;
			right: 0;
			top: 0;
		}
		@include dark-mode {
			background-color: rgba(242, 244, 247, 0.064);
			color: #5b6276;
			border: 1px solid #5b6276;
		}
	}
	.campaign-card-body {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.info-card {
		display: flex;
		align-items: center;
		gap: 5px;
		.small-text {
			font-size: 12px;
		}
	}
}
.circle-icon {
	border-radius: 50%;
	height: 35px;
	width: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f2f2f2;
	@include dark-mode {
		background-color: #5b6276;
	}
}

.text-right {
	text-align: right;
}

.carousel-wrpp {
	height: 220px;
	.carousel-indicators {
		button {
			@include dark-mode {
				background-color: rgba(242, 244, 247, 0.064) !important;
			}
		}
	}
	// .carousel-item {
	// 	padding-top: 10px;
	// }
	.carousel-inner {
		overflow: visible;
	}
}

.visiblity-visible {
	visibility: visible;
}
.visiblity-hidden {
	visibility: hidden;
}

.tag-container {
	display: flex;
	align-items: center;
	gap: 5px;
	flex-wrap: wrap;
	.tag {
		border-radius: 10px;
		background-color: #f2f4f7;
		color: #5b6276;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		@include dark-mode {
			background-color: rgba(242, 244, 247, 0.064);
		}
		padding: 3px 6px;
		font-size: 10px;
	}
}

.mobile-ui {
	height: auto;
	width: 100%;
	min-height: 300px;
	border-radius: 20px 20px 0 0;
	border-bottom: 1px solid #5b6276;
	padding: 18px;
	padding-bottom: 0;
	@include dark-mode {
		background-color: rgba(242, 244, 247, 0.064);
	}
	.screen {
		background-color: #fff;
		@include dark-mode {
			background-color: rgba(242, 244, 247, 0.064);
		}
		border-radius: 20px 20px 0 0;
		height: 100%;
		min-height: 300px;
	}
}
.search-placeholder {
	.search-input {
		width: 98%;
		height: 20px;
		border: 1px solid #5b6276;
		border-radius: 20px;
		padding: 18px;
		background-color: transparent;
		@include dark-mode {
			background-color: rgba(242, 244, 247, 0.064);
		}
		margin-bottom: 10px;
		overflow: hidden;
		position: relative;
	}
	.search-button {
		position: absolute;
		width: 40px;
		height: 100%;
		background-color: #f2f4f7;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: scale(1.3);
		@include dark-mode {
			background-color: rgba(242, 244, 247, 0.064);
		}
	}
}

.css-wsp0cs-MultiValueGeneric {
	@include dark-mode {
		color: #fff;
	}
}
.workflow-card {
	padding: 15px;
	border-radius: 10px;
	border: 1px solid #f1f2f4;
	@include dark-mode {
		border: 1px solid #f1f2f41f;
	}
}
.workflow-output-tab,
.image-output-tab {
	max-height: 400px;
	overflow-y: scroll;
	.image-container {
		position: relative;
		width: 100%;
		padding-top: calc(2 / 3 * 100%);
		overflow: hidden;
	}
}
.output-data-grid {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(1, 100%);
	gap: 23px;
	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 48.5%);
	}
	@media (min-width: 920px) {
		grid-template-columns: repeat(4, 23.5%);
	}
	.output-card-item.add {
		.black-card {
			border: 1px solid transparent;
		}
	}
	.output-card-item.add:hover {
		.black-card {
			border: 1px solid var(--bs-grey-card-border-color);
		}
		.theme-gray-text {
			color: #000 !important;
			@include dark-mode {
				color: #fff !important;
			}
		}
	}
	.output-card-item {
		.card-body-section {
			height: fit-content !important;
		}
		.black-card {
			background-color: #fff;
			height: 315px;
			overflow: hidden;
			@include dark-mode {
				background-color: #000;
			}
			border: 1px solid var(--bs-grey-card-border-color);
			border-radius: 8px;
		}
	}
}

.output-card-action-btn-wrapper {
	button {
		svg {
			fill: rgba(0, 0, 0, 0.4);
			@include dark-mode {
				fill: $grey-40;
			}
			transform: scale(2);
		}

		svg:hover {
			fill: var(--bs-primary);
			@include dark-mode {
				fill: #fff;
			}
		}
	}
	.active-svg {
		svg {
			fill: var(--bs-primary);
			@include dark-mode {
				fill: #fff;
			}
		}
	}
}

.hidden-btn {
	border: none !important;
	outline: none !important;
	background-color: transparent !important;
	box-shadow: none !important;
	padding: 0 !important;
	svg {
		width: 12px;
		height: 12px;
	}
	:hover {
		border: none !important;
		outline: none !important;
		background-color: transparent !important;
		box-shadow: none !important;
	}
}
.action-btn {
	svg {
		width: 12px;
		height: 12px;
	}
}

.top-start {
	transform: translateY(-100%) !important;
}
.list-shadow {
	box-shadow: 0px 2px 3px 2px rgba(242, 244, 247, 0.064);
}
.rdt_TableHeader,
.sc-dlMCtj,
.sc-dIvsgl,
.rdt_Table,
.rdt_TableBody,
.rdt_TableRow,
.laPPHD {
	background-color: var(--bs-card) !important;
	@include dark-mode {
		color: #fff !important;
	}
}
.rdt_TableRow:hover {
	outline: none !important;
	background-color: #5b6276 !important;
	border-bottom-color: #363a44 !important;
}
.rdt_Pagination {
	background-color: var(--bs-card) !important;
	@include dark-mode {
		color: var(--bs-secondary-color) !important;
	}
}
.rdt_TableHead,
.rdt_TableHeadRow {
	background-color: var(--bs-body-bg) !important;
	@include dark-mode {
		color: var(--bs-secondary-color) !important;
	}
}

.workflow-output-tab-controller {
	.nav-link {
		color: rgba(0, 0, 0, 0.4);
		@include dark-mode {
			color: $grey-40;
		}
		border-top-left-radius: 8px !important;
		border-top-right-radius: 8px !important;
	}
}

//Dropdown style

.DropdownMenuContent,
.DropdownMenuSubContent {
	min-width: 220px;
	// background-color: white;
	border-radius: 6px;
	padding: 5px;
	box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
		0px 10px 20px -15px rgba(22, 23, 24, 0.2);
	animation-duration: 400ms;
	animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	will-change: transform, opacity;
	// @include dark-mode {
	// 	background-color: rgba(15, 15, 15);
	// }
	z-index: 99999999999999;
}
.kb-page.DropdownMenuContent,
.kb-page.DropdownMenuSubContent {
	background-color: white;

	@include dark-mode {
		background-color: #1f2128;
	}
	.DropdownMenuArrow {
		fill: white;
		@include dark-mode {
			fill: #1f2128;
		}
	}
	.DropdownMenuItem[data-highlighted],
	.DropdownMenuCheckboxItem[data-highlighted],
	.DropdownMenuRadioItem[data-highlighted],
	.DropdownMenuSubTrigger[data-highlighted] {
		background-color: rgba(0, 0, 0, 0.06);

		@include dark-mode {
			background-color: rgba(15, 15, 15);
		}
	}
	.DropdownMenuSubTrigger[data-state='open'] {
		background-color: rgba(0, 0, 0, 0.06);

		@include dark-mode {
			background-color: rgba(15, 15, 15);
		}
		// color: var(--violet-11);
	}
}
.other-page.DropdownMenuContent,
.other-page.DropdownMenuSubContent {
	background-color: white;

	@include dark-mode {
		background-color: rgba(15, 15, 15);
	}
	.DropdownMenuArrow {
		fill: white;
		@include dark-mode {
			fill: rgba(15, 15, 15);
		}
	}
	.DropdownMenuItem[data-highlighted],
	.DropdownMenuCheckboxItem[data-highlighted],
	.DropdownMenuRadioItem[data-highlighted],
	.DropdownMenuSubTrigger[data-highlighted] {
		background-color: rgba(0, 0, 0, 0.06);

		@include dark-mode {
			background-color: var(--bs-body-bg);
		}
	}

	.DropdownMenuSubTrigger[data-state='open'] {
		background-color: rgba(0, 0, 0, 0.06);

		@include dark-mode {
			background-color: var(--bs-body-bg);
		}
		// color: var(--violet-11);
	}
}

.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
	animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
	animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
	animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
	animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
	font-size: 13px;
	line-height: 1;
	// color: var(--violet-11);
	border-radius: 3px;
	display: flex;
	align-items: center;
	height: 25px;
	padding: 0 5px;
	position: relative;
	padding-left: 25px;
	user-select: none;
	outline: none;
	cursor: pointer;
}

.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
	color: rgb(188, 186, 199);
	pointer-events: none;
}

.DropdownMenuLabel {
	padding-left: 25px;
	font-size: 12px;
	line-height: 25px;
	// color: var(--mauve-11);
}

.DropdownMenuSeparator {
	height: 1px;
	background-color: var(--bs-grey-card-border-color);
	margin: 5px;
}

.DropdownMenuItemIndicator {
	position: absolute;
	left: 0;
	width: 25px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.count-text {
	flex-shrink: 0;
	border-radius: 50%;
	border: 1px solid rgba(15, 15, 15);
	margin-bottom: 0;
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include dark-mode {
		border: 1px solid #fff;
	}
}

.IconButton {
	font-family: inherit;
	border-radius: 100%;
	height: 35px;
	width: 35px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--violet-11);
	background-color: white;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	@include dark-mode {
		background-color: rgba(15, 15, 15, 0.6);
	}
}
.IconButton:hover {
	outline: none;
	background-color: rgba(0, 0, 0, 0.06);
	@include dark-mode {
		background-color: rgba(15, 15, 15, 0.3);
	}
}
// .IconButton:focus {
// 	box-shadow: 0 0 0 2px black;
// }
.TextButton {
	font-family: inherit;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	// box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	padding: 10px 18px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	border: none;
	outline: none;
	@include dark-mode {
		// background-color: rgba(15, 15, 15, 0.6);
	}
}
.arrow-icon {
	// color: var(--bs-primary);
}

.TextButton.kb-page:hover {
	outline: none;
	// background-color: rgba(0, 0, 0, 0.06);
	@include dark-mode {
		// background-color: var(--bs-body-bg);
	}
}
.TextButton.other-page:hover {
	outline: none;
	// background-color: rgba(0, 0, 0, 0.06);
	@include dark-mode {
		// background-color: rgba(46, 46, 46, 0.863);
	}
}
// .TextButton:focus {
// 	// box-shadow: 0 0 0 2px black;
// }
.RightSlot {
	margin-left: auto;
	padding-left: 20px;
	// color: var(--mauve-11);
}
// [data-highlighted] > .RightSlot {
// 	color: white;
// }
// [data-disabled] .RightSlot {
// 	color: var(--mauve-8);
// }

@keyframes slideUpAndFade {
	from {
		opacity: 0;
		transform: translateY(2px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideRightAndFade {
	from {
		opacity: 0;
		transform: translateX(-2px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slideDownAndFade {
	from {
		opacity: 0;
		transform: translateY(-2px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideLeftAndFade {
	from {
		opacity: 0;
		transform: translateX(2px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

::selection {
	background: var(--bs-primary);
	color: var(--bs-white);
}

::-webkit-selection {
	background: var(--bs-primary);
	color: var(--bs-white);
}

::-moz-selection {
	background: transparent;
	color: inherit;
}

.ReactTags__selected {
	// border: 1px solid var(--bs-grey-card-border-color);
	border-radius: 8px;
	gap: 10px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	position: relative;

	.tag-wrapper {
		// background-color: ;
		// display: flex;
		// align-items: center;
		// gap: 3px;
		background-color: rgb(245, 245, 245);
		border-radius: 25px;

		@include dark-mode {
			background-color: #000000;
		}
		padding: 10px;
		.ReactTags__remove {
			border-radius: 50%;
			outline: none;
			margin-left: 5px;
			border: none;
			svg {
				fill: #000;
				@include dark-mode {
					fill: #fff;
				}
			}
		}
	}
	.ReactTags__tagInput {
		bottom: 0;
		left: 0;
		width: 100%;
		// padding: 5px;
		input {
			@extend .custom-styled-textarea;
			width: 100%;
			padding: 1rem !important;
		}
	}
}

.absolute-positioned {
	.ReactTags__selected {
		padding-bottom: 70px;
	}
	.ReactTags__tagInput {
		margin-top: 30px;
		position: absolute;
	}
}

.page-item.active {
	background-color: var(--bs-primary);
}

.custom-tag-input {
	.ReactTags__tagInputField {
		background-color: rgba(0, 0, 0, 0.06) !important;
		color: #000 !important;

		@include dark-mode {
			background-color: #1f2128 !important;
			color: #fff !important;
		}
		width: 100% !important;
		border-radius: 8px !important;
		padding: 6px !important;
		border: none !important;
		outline: none !important;
	}
	.ReactTags__tagInputField::placeholder {
		color: $grey-40 !important;
	}
	.ReactTags__tagInputField:focus {
		border: 1px solid #fff !important;
	}
}
