$ds-milk-green: #e1fc62;
$ds-dark: #0b0c0e;
$ds-dark-50: #38393a;
$ds-dark-100: #333333;
$ds-grey: #e9eaec;
$ds-grey-100: #dee0e3;
$ds-text-grey: #666974;
$ds-text-grey-50: #adadad;
$ds-text-grey-100: #6f717c;
$ds-text-dark: #14151a;
$ds-icon-grey: #5e636e;
$ds-light: #f7f7f8;
$ds-white: #fff;

.border-bottom-ds-dark {
	border-bottom: 1px solid #0b0c0e;
}
.border-bottom-transparent {
	border-bottom: 1px solid transparent;
}
.text-ds-text-grey {
	color: $ds-text-grey;
}
.text-ds-icon-grey {
	color: $ds-icon-grey;
}
.text-ds-text-grey-100 {
	color: $ds-text-grey-100;
}

.text-ds-text-grey-50 {
	color: $ds-text-grey-100;
}

.text-ds-text-dark {
	color: $ds-text-dark;
}

.bg-ds-grey {
	background-color: $ds-grey;
}

.bg-ds-dark {
	background-color: $ds-dark !important;
}
.bg-ds-dark-50 {
	background-color: $ds-dark-50;
}

.bg-ds-dark-100 {
	background-color: $ds-dark-100;
}

.disseminate-container {
	width: 100%;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	background-color: $ds-light;
	.text-black {
		@include dark-mode {
			color: #fff !important;
		}
		color: #000;
	}

	.plyr {
		width: 100%;
		height: 100%;
	}
	.plyr__control.plyr__control--overlaid,
	.plyr--video .plyr__control.plyr__tab-focus,
	.plyr--video .plyr__control:hover,
	.plyr--video .plyr__control[aria-expanded='true'] {
		background-color: $ds-dark;
		color: $ds-milk-green;
	}
}
.disseminate-header {
	position: fixed;
	top: 0;
	left: 0;
	padding: 12px;
	width: 100%;
	height: 90px;
	max-height: 90px;
	z-index: 100;
	background-color: #fff;
	.title {
		text-wrap: wrap;
		max-width: 80%;
	}
	-webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.disable-drag-indicator {
	.react-modal-sheet-drag-indicator {
		display: none;
	}
}
.text-wrap {
	text-wrap: wrap;
}
.disseminate-body {
	margin-top: 70px;
	height: calc(100vh - 70px);
	overflow-x: hidden;
	overflow-y: auto;
	padding: 12px;
	padding-bottom: 70px;
	-webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.disseminate-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 12px;
	width: 100%;
	height: 110px;
	max-height: 110px;
	background: rgb(255, 255, 255);

	background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(255, 255, 255, 1) 35%,
		rgba(255, 255, 255, 0) 100%
	);
	z-index: 99999999;
	-webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.disseminate-sheet {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	.react-modal-sheet-container {
		box-shadow: 0 -10px 16px 0 rgba(20, 21, 26, 0.03) !important;
	}
	.disseminate-sheet-header {
		background-color: $ds-white;
		border-top-right-radius: 24px !important;
		border-top-left-radius: 24px !important;
	}
	.disseminate-sheet-content {
		background-color: $ds-white;
	}

	.disseminate-sheet-header.bg-black {
		background-color: rgba(15, 15, 15, 1) !important;
		border-top-right-radius: 24px !important;
		border-top-left-radius: 24px !important;
	}
	.disseminate-sheet-content.bg-black {
		background-color: rgba(15, 15, 15, 1) !important;
	}

	.disseminate-sheet-content {
		overflow-y: auto;
		padding: 16px;
	}
}
.thumbnail-container {
	width: 100%;
	height: 193px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.icon-wrapper {
		position: absolute;
		transform: translate(-50%, -50%);
	}
}

.thumbnail-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	max-width: 100%;
	max-height: 100%;
}

.preview-container {
	.image-preview-card {
		.icon-wrapper {
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
			height: 88px;
			width: 88px;
			left: 50%;
			top: 50%;
			background-color: #0b0c0e;
			z-index: 9999999;
			border-radius: 50%;
		}
	}
}
.video-wrapper {
	width: 100%;
	height: 193px;
	position: relative;

	video {
		object-fit: cover;
	}
	.icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 44px;
		width: 44px;
		left: 50%;
		top: 50%;
		background-color: $ds-dark;
		z-index: 99999999999;
		border-radius: 50%;
	}
	.overlay {
		z-index: 50;
		display: grid;
		place-items: center;
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: transparent;

		.spinner-border {
			margin-right: 0 !important;
		}
	}
}
.video-preview-card {
	.plyr {
		z-index: 99;
		position: relative;
		height: 100%;
		.plyr__video-wrapper {
			video {
				object-fit: cover !important;
			}
		}
	}

	.plyr::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
	}
	.plyr__control.plyr__control--overlaid,
	.plyr--video .plyr__control.plyr__tab-focus,
	.plyr--video .plyr__control:hover,
	.plyr--video .plyr__control[aria-expanded='true'] {
		background-color: $ds-dark;
		color: $ds-milk-green;
	}
}
// .control-hidden {
// 	.plyr__controls {
// 		visibility: hidden;
// 		display: none;
// 	}
// }
.image-preview-card {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.overlay {
		z-index: 50;
		display: grid;
		place-items: center;
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
	}
}
.video-preview-card {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	.overlay {
		z-index: 50;
		display: grid;
		place-items: center;
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: transparent;
	}
	.icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 88px;
		width: 88px;
		left: 50%;
		top: 50%;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 99999999999;
		border-radius: 50%;
	}
}
.full-screen-mode {
	position: absolute;
	top: 0;
	left: 0;
	.overlay {
		display: grid;
		place-items: center;
		top: 50%;
		position: fixed;
		transform: translate(0%, -50%);
		z-index: 999999999;
	}
	video {
		position: absolute;
		transform: rotate(90deg);

		transform-origin: bottom left;
		width: 100vh;
		height: 100vw;
		margin-top: -100vw;
		object-fit: cover;

		z-index: 999999999;
		visibility: visible;

		@supports (width: 100dvh) and (height: 100dvw) and (margin-top: -100dvw) {
			width: 100dvh;
			height: 100dvw;
			margin-top: -100dvw;
		}
	}
	.icon-wrapper {
		transform: rotate(90deg);
	}
	.disseminate-header {
		position: absolute;
		z-index: 99999999999;
		top: 0px;
		transform: rotate(90deg) translate(0px, -100px);
		height: 90px;
		background-color: rgba(0, 0, 0, 0.6);
		transform-origin: top left;
		left: 100%;
		width: 100vh;
		transition: all 0.2s linear;
	}
	.disseminate-header.visible {
		transform: rotate(90deg) translate(0px, 0px);
	}

	.dropdown-wrapper {
		background-color: $ds-grey;
		color: $ds-text-grey;
	}
	.info-wrapper {
		position: fixed;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
		-webkit-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		transform-origin: bottom left;
		width: 40vh;
		height: 100vw;
		margin-top: 0;
		bottom: 0;
		-o-object-fit: cover;
		object-fit: cover;
		z-index: 999999999;
		visibility: visible;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
		@supports (height: 100dvw) {
			height: 100dvw;
		}

		.info-header {
			position: fixed;
			height: 90px;
			width: 100%;
			top: 0;
			left: 0;
		}
		.info-body {
			overflow-y: auto;
			margin-top: 80px;
			max-height: calc(100% - 100px);
		}
	}
}
.info-wrapper {
	position: fixed;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	width: 40vh;
	height: 100vw;
	margin-top: 0;
	bottom: 0;
	-o-object-fit: cover;
	object-fit: cover;
	z-index: 999999999;
	visibility: visible;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;

	@supports (height: 100dvw) {
		height: 100dvw;
	}

	.info-header {
		position: fixed;
		height: 90px;
		width: 100%;
		top: 0;
		left: 0;
	}
	.info-body {
		overflow-y: auto;
		margin-top: 80px;
		max-height: calc(100% - 100px);
	}
}
.full-screen-mode.info-visible {
	position: absolute;
	top: 0;
	left: 0;
	.overlay {
		display: grid;
		place-items: center;
		position: fixed;
		z-index: 999999999;
		height: 60vh;
		top: 0;
		transform: translate(0, 0);
	}
	video {
		position: absolute;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
		-webkit-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		transform-origin: bottom left;
		width: 60vh;
		height: 100vw;
		margin-top: -100vw;
		-o-object-fit: cover;
		object-fit: cover;
		z-index: 99999999;
		visibility: visible;
		transition: all 0.2s linear;

		@supports (height: 100dvw) and (width: 60dvh) and (margin-top: -100dvw) {
			height: 100dvw;
			width: 60dvh;
			margin-top: -100dvw;
		}
	}
	.icon-wrapper {
		transform: rotate(90deg);
	}
	.disseminate-header {
		position: absolute;
		z-index: 99999999999;
		top: 0px;
		-webkit-transform: rotate(90deg) translate(0px, -100px);
		-ms-transform: rotate(90deg) translate(0px, -100px);
		transform: rotate(90deg) translate(0px, -100px);
		height: 90px;
		background-color: rgba(0, 0, 0, 0.6);
		-webkit-transform-origin: top left;
		-ms-transform-origin: top left;
		transform-origin: top left;
		left: 100%;
		width: 60vh;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
	}
	.disseminate-header.visible {
		transform: rotate(90deg) translate(0px, 0px);
	}

	.dropdown-wrapper {
		background-color: $ds-grey;
		color: $ds-text-grey;
	}
	.info-wrapper {
		position: fixed;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
		-webkit-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		transform-origin: bottom left;
		width: 40vh;
		height: 100vw;
		margin-top: 0;
		bottom: 40vh;
		-o-object-fit: cover;
		object-fit: cover;
		z-index: 999999999;
		visibility: visible;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;

		@supports (height: 100dvw) and (width: 40dvh) {
			height: 100dvw;
			width: 40dvh;
		}

		.info-header {
			position: fixed;
			height: 90px;
			width: 100%;
			top: 0;
			left: 0;
		}
		.info-body {
			overflow-y: auto;
			margin-top: 80px;
			max-height: calc(100% - 100px);
		}
	}
}
.carousel-wrapper {
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999999999;
	.carousel-inner {
		height: 100%;
	}
}
.full-screen.carousel-wrapper {
	position: fixed;

	.disseminate-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		transition: all 0.2s linear;
		transform: translateY(-90px);
	}

	.disseminate-header.visible {
		transform: translateY(0px);
	}
}

.info-wrapper-img {
	position: fixed;
	width: 100%;
	height: 50%;
	bottom: -50%;
	left: 0;
	z-index: 999999999;
	transition: all 0.2s linear;
	transform: translate(0, 0);
}
.info-wrapper-img.info-visible {
	bottom: 0;
}
.document-wrapper {
	width: 100%;
	height: 193px;
	position: relative;
	border: 1px solid $ds-grey-100;
	border-radius: 8px;
	padding: 28px;
	padding-bottom: 0;
	overflow: hidden;
	p {
		word-break: break-all;
		text-wrap: wrap;
	}
}
.fw-semibold {
	font-weight: 600;
}
.fs-14 {
	font-size: 14px;
}
.fs-16 {
	font-size: 16px;
}
.fs-18 {
	font-size: 18px;
}
.fs-20 {
	font-size: 20px;
}
.fs-24 {
	font-size: 24px;
}
.fs-32 {
	font-size: 32px;
}
.fs-36 {
	font-size: 36px;
}
.h-24 {
	height: 24px !important;
}
.w-24 {
	width: 24px !important;
}
.main-menu.bg-milk-green {
	background-color: $ds-milk-green !important;
	height: 60px !important;
	width: 60px !important;
	// position: absolute;
	left: 0px;
	border-radius: 50%;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	// transform: scale(0.8);

	img {
		width: 24px !important;
		height: 24px !important;
	}
}
.icon-btn.bg-milk-green {
	background-color: $ds-milk-green !important;
	height: 66px !important;
	width: 66px !important;

	border-radius: 50%;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 24px !important;
		height: 24px !important;
	}
}
.back-drop {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.486);
	left: 0;
	top: 0;
	z-index: 1000;
}

.audio-bar {
	width: 8px;
	border-radius: 30px;
}
.custom-ds-input {
	height: 55px !important;
	border-radius: 12px !important;
	background-color: $ds-light !important;
	font-size: 16px;
	&:focus {
		border-color: #14151a;
		box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(12, 12, 12, 0.25);
	}
}
.custom-ds-textarea {
	@extend .custom-ds-input;

	height: auto !important;
}
.blured {
	filter: blur(4px);
}
.scale-0 {
	scale: 0;
}
.scale-1 {
	scale: 1;
}
.modal-close-btn {
	background-color: var(--bs-grey-bg) !important;
	border-radius: 100%;
	height: 30px;
	width: 30px;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.steps-wrapper {
	padding-bottom: 100px;
}

.dropdown-steps-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 14px;
	padding-bottom: 70px;
	background-color: var(--bs-body-bg);
	transition: all 0.5s;
	z-index: 999999999;

	.step-card {
		.number-badge {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $ds-grey;
			color: $ds-text-grey;
			font-family: 'Overpass Mono', system-ui;
			height: 32px;
			width: 50px;
			border-radius: 24px;
			padding: 0 12px;
			p {
				transform: translateY(2px);
			}
		}
		.number-badge.active {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $ds-milk-green;
			color: $ds-text-grey;
			font-family: 'Overpass Mono', system-ui;
			height: 32px;
			width: 50px;
			border-radius: 24px;
			padding: 0 12px;
			p {
				transform: translateY(2px);
			}
		}
	}
}

.dark-icon-btn {
	// position: absolute;
	// bottom: 80px;
	// right: 16px;
	z-index: 50;
	.main-menu {
		width: 60px;
		height: 60px;
		background: #000;
		border-radius: 50%;
		color: #000;
		display: flex;
		align-items: center;
		justify-content: center;
		// transform: scale(0.8);
		svg {
			fill: #fff;
		}
		img {
			width: 30px;
			height: 30px;
		}
	}
}

.dropdown-wrapper {
	background-color: $ds-dark-50;
	color: $ds-white;
	font-family: 'Overpass Mono', system-ui;
	padding: 6px 12px;
	text-transform: uppercase;
	border-radius: 24px;
}

.preview-container {
	position: absolute;
	left: 0;
	top: 0;
	padding: 16px;
	width: 100%;
	.bottom-section {
		position: absolute;
		bottom: 20%;
		left: 0;
		width: 100%;
	}
}

.mission-step-details {
	ul {
		padding: 0 14px;
	}
}

@media (min-width: 768px) {
	.disseminate-container {
		width: 50%;
		margin: 0 auto;
	}
	.disseminate-header {
		width: 50%;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}

	.disseminate-footer {
		width: 50%;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
	}
	.disseminate-sheet {
		left: 50% !important;
		transform: translateX(-50%) !important;
	}
	.full-screen-mode.info-visible .info-wrapper,
	.full-screen-mode .info-wrapper {
		height: 50vw;
		@supports (height: 50dvw) {
			height: 50dvw;
		}
	}
}

@media (min-width: 922px) {
	.disseminate-header,
	.disseminate-container,
	.disseminate-footer {
		width: 25%;
	}

	.full-screen-mode.info-visible .info-wrapper,
	.full-screen-mode .info-wrapper {
		height: 25vw;
		@supports (height: 25dvw) {
			height: 25dvw;
		}
	}
}

.plyr:fullscreen {
	video {
		height: 100% !important;
	}
}
.active-fade-in {
	&:active {
		-webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
		animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	}
}
.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.hover-scale-up-center {
	&:hover {
		-webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
		animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	}
}

.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.text-ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-8-28 13:50:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2024-8-28 15:7:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2024-8-28 15:12:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
@keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
