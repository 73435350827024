// #030303,#000000,#ffffff,#d3d3d3,#1e1f26,#545454,#c1c1c1

.bg-n-black {
	background-color: #000000;
}
.bg-n-black-1 {
	background-color: #030303;
}
.bg-n-white {
	background-color: #ffffff;
}
.bg-n-grey {
	background-color: #545454;
}
.bg-n-grey-1 {
	background-color: #d3d3d3;
}
.bg-n-grey-2 {
	background-color: #c1c1c1;
}
.text-n-black {
	background-color: #000000;
}
.text-n-white {
	background-color: #ffffff;
}
.text-n-grey {
	background-color: #545454;
}
.text-n-grey-1 {
	background-color: #d3d3d3;
}
.text-n-grey-2 {
	background-color: #c1c1c1;
}
.news-dashboard-container {
	display: flex;
	align-items: start;
}
.news-content-wrapper {
	width: calc(100% - 85px);
	height: 100%;
	// overflow-y: scroll;
	// overflow-x: hidden;
	transform: translateX(calc(100% - calc(100% - 85px)));
}
.navigation-bar {
	height: 100%;
	width: 80px;
	min-width: 80px;
	position: fixed;
	left: 0;
	top: 0;
}
.navigation-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 60px 0 100px;
}
.icon-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 30px;

	.navigation-icon {
		width: 20px;
		height: 20px;
		margin: 0;
		opacity: 0.8;
	}
	.navigation-icon.active {
		opacity: 1;
	}
}

.bottom-shadow {
	box-shadow: rgba(0, 0, 0, 0.02) 0px 7px 9px;
}
.sticky-news-header {
	height: auto;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: #fff;
	.btn-dark:hover {
		background-color: transparent !important;
		color: #000 !important;
		border: 1px solid #000 !important;
	}
	.btn-dark.active {
		background-color: transparent !important;
		color: #000 !important;
		border: 1px solid #000 !important;
	}
}
.news-grid {
	// display: flex;
	// align-items: flex-start;

	// gap: 5px;
	// flex-wrap: wrap;
	// background-color: #000000;
	overflow-x: hidden;
	span {
		// height: 300px;
	}
}
.news-card {
	// height: 300px;
	// width: calc(25% - 10px);
	background-size: cover;
	background-position: center;
	// margin: 5px;
	overflow: hidden;
	flex-grow: 0;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.06) 0px 7px 9px;
}
.backdrop {
	background-color: rgba(0, 0, 0, 0.6);
	width: 100%;
	height: 100%;
}
.news-title {
	// color: rgba(255, 255, 255, 0.8);
	font-weight: 800;
	color: #000;
	font-size: 24px;
}
.news-description {
	color: #fff;
	color: #000;
}
.publishe-at {
	font-size: 12px;
}
.generated-output-type-container {
	position: sticky;
	right: 0;
	height: 100%;
	display: grid;
	grid-template-rows: 150px 1fr;
	background-color: #e2e2e2;
	@include dark-mode {
		background-color: rgba(0, 0, 0, 0.06);
	}
}
.type-title-container {
	background-color: #e2e2e2;
	position: sticky;
	top: 0;
	left: 0;
	height: auto;
}
.type-form-bottom-container {
	background-color: #e2e2e2;
}
.type-card-wrapper {
	overflow-y: scroll;
}
.type-card {
	height: 200px;
}

.neumorphic-card {
	border-radius: 50px;
	background: linear-gradient(145deg, #dddfe5, #f2f4ff);
	box-shadow: 20px 20px 60px #f6f6f6, -20px -20px 60px #ffffff;
}
.neumorphic-card.white {
	background: linear-gradient(145deg, #e6e6e6, #ffffff);
	box-shadow: 20px 20px 60px #bdbdbd, -20px -20px 60px #ffffff;
}
