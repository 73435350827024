.search-dropdown {
	left: 0;
	top: 50px;
	z-index: 100;
	background-color: #f0f0f0;
	max-height: 300px;
	border-radius: 10px;
	overflow: hidden;
	ul {
		list-style-type: none;
		padding: 0;
	}
	li {
		display: flex;
		align-items: center;
		gap: 5px;
		cursor: pointer;
		background-color: #f0f0f0;
		@include dark-mode {
			background-color: #212529;
		}
		span {
			font-size: 12px;
			color: #bebebe;
			@include dark-mode {
				color: #ffffff;
			}
		}
	}
	li:hover {
		background-color: #f9f9f9;
		@include dark-mode {
			background-color: #000;
		}
	}
}

.profile-background {
	background: linear-gradient(to bottom, rgb(220, 220, 220), #fff);

	@include dark-mode {
		background: linear-gradient(to bottom, rgb(35, 35, 35), #000);
	}
	.avator {
		overflow: hidden;
		background: rgb(220, 220, 220);

		@include dark-mode {
			background: rgb(35, 35, 35);
		}
	}
}
.user-profile-container {
	background-color: #f4f2ee;
	@include dark-mode {
		background-color: transparent;
	}
	.card-container-bg {
		background-color: #fff;
		@include dark-mode {
			background-color: #000;
		}
	}
	.profile-backdrop {
		background: linear-gradient(to bottom, rgb(220, 220, 220), #fff);
		height: 200px;
		width: 100%;
		position: relative;

		@include dark-mode {
			background: linear-gradient(to bottom, rgb(35, 35, 35), #000);
		}
		.avator {
			width: 140px;
			height: 140px;
			border-radius: 50%;
			position: absolute;
			bottom: -50px;
			left: 100px;
			transform: translate(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
			background: rgb(220, 220, 220);

			@include dark-mode {
				background: rgb(35, 35, 35);
			}
		}
	}
	.user-detail-container {
		margin-top: 40px;
		.designation {
			font-size: 16px;
		}
	}
	.company-avator {
		width: 80px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgb(220, 220, 220);

		@include dark-mode {
			background: rgb(35, 35, 35);
		}
	}
}
