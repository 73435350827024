#asset-manager {
	body {
		min-height: 100vh;
		// min-height: -webkit-fill-available;
	}

	html {
		// height: -webkit-fill-available;
	}

	main {
		height: calc(100vh - 65px);
		// height: -webkit-fill-available;
		max-height: calc(100vh - 65px);
		overflow-x: auto;
		overflow-y: hidden;
	}
	.scrollarea {
		overflow-y: auto;
	}
	.ReactTags__selected {
		border: none;
		padding: 0;
		border-radius: 8px;
		gap: 10px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		position: relative;

		.tag-wrapper {
			// background-color: ;
			// display: flex;
			// align-items: center;
			// gap: 3px;
			background-color: rgb(245, 245, 245);
			border-radius: 25px;

			@include dark-mode {
				background-color: #000000;
			}
			padding: 10px;
			.ReactTags__remove {
				border-radius: 50%;
				outline: none;
				margin-left: 5px;
				border: none;
				svg {
					fill: #000;
					@include dark-mode {
						fill: #fff;
					}
				}
			}
		}
		.ReactTags__tagInput {
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 5px;
			input {
				@extend .form-control;
				width: 100%;
				border-radius: 10px;
				padding: 10px;
			}
		}
	}

	.absolute-positioned {
		.ReactTags__selected {
			padding-bottom: 70px;
		}
		.ReactTags__tagInput {
			margin-top: 30px;
			position: absolute;
		}
	}
	.sc-hzhKNl.fOHzfa {
		height: 85%;
		overflow-y: scroll;
	}

	.rdt_Pagination {
		.sc-dtBeHJ {
			button {
				svg {
					@include dark-mode {
						fill: #fff;
					}
				}
			}
			.ihsNNY:disabled {
				svg {
					@include dark-mode {
						fill: #999999;
					}
				}
			}
			.ihsNNY:hover {
				@include dark-mode {
					background-color: rgba(126, 126, 126, 0.12);
				}
			}
			.ihsNNY:focus {
				@include dark-mode {
					background-color: rgb(103 103 103 / 12%);
				}
			}
		}
	}
	.action-btn {
		background-color: rgba(0, 0, 0, 0.06);
		border-radius: 100%;
		outline: none;
		border: none;
		height: 40px;
		width: 40px;
		@include dark-mode {
			background-color: rgba(15, 15, 15, 0.6);
		}

		&:active {
			background-color: rgb(103 103 103 / 12%);
		}
	}
}
.sc-eeDSqt {
	option {
		background-color: var(--bs-body-bg);
	}
}

.avatar-stack {
	display: flex;
	flex-direction: row-reverse;
	// flex-wrap: wrap;
	justify-content: flex-end;
	.avatar {
		overflow: hidden;
		border: 2px solid #fff;
	}
}
.hidden-close-icon {
	.btn-close {
		display: none;
	}
}

.sc-jsJARu {
	background-color: var(--bs-body-bg) !important;
	@include dark-mode {
		color: #fff;
	}
}

#search-asset-offcanvas {
	width: 400px;
	padding-top: 65px;
}

#assetChatCanvas {
	width: 35%;
	.chat-card {
		height: 130px;
		background-color: var(--bs-chat-receiver-bg);
	}

	.chat-card:hover {
		background-color: rgba(126, 126, 126, 0.12);
	}
	.chat-card:active {
		background-color: var(--bs-chat-receiver-bg);
	}
}
